<script setup lang="ts">
const { t } = useT();
const { open } = useAppModals();

withDefaults(
	defineProps<{
		hideLabel?: boolean;
	}>(),
	{
		hideLabel: false
	}
);

const labelsData = {
	bronze: t("scratchCards.promoCard.label.name1"),
	silver: t("scratchCards.promoCard.label.name2"),
	gold: t("scratchCards.promoCard.label.name3")
};

const { data, activeCard, durationLeft } = useScratchCard();

const goldenCardData = computed(() =>
	data.value?.cards?.find((card) => card?.slug === "gold" && card?.status === "completed")
);

const claimStatus = computed(() => activeCard.value?.status === "ready_for_activate");

const btnText = computed(() =>
	claimStatus.value ? t("scratchCards.promoCard.btn.name") : t("scratchCards.promoCard.btn.name2")
);

const freeText = computed(() => labelsData[activeCard.value?.slug]);

const handleCardClick = () => {
	if (goldenCardData.value) {
		return;
	}
	if (claimStatus.value) {
		open("LazyOModalScratchCardsPlayForWin", { cardType: activeCard.value?.slug });
		return;
	}
	window?.$cash?.$router?.push?.("/cash/deposit-by-money/");
};

const { durationLeft: goldenCardTimeLeft, reset: resetGoldenCardTimeLeft } = useCountdown({
	timestamp: goldenCardData.value?.timer as string,
	formatToken: "HH[:]mm[:]ss"
});

watch(
	() => goldenCardData.value?.timer,
	() => {
		resetGoldenCardTimeLeft(goldenCardData.value?.timer ?? "");
	}
);
</script>

<template>
	<ACard
		:imgAttrs="{
			width: 270,
			height: 492,
			src: `/nuxt-img/scratch-cards/helloween/promo/promo-${activeCard?.slug}.webp`,
			loading: 'lazy',
			alt: 'scratch-promotion-img'
		}"
		width="328px"
		height="246px"
		class="card-scratch"
		@click="handleCardClick"
	>
		<div v-if="!hideLabel" class="free">
			<NuxtIcon :name="`48/${activeCard?.slug}-free`" filled class="icon" />
			<AText variant="tempe" class="text-tlalnepantla free-text text-cannes" :modifiers="['semibold', 'uppercase']">
				{{ freeText }}
			</AText>
		</div>
		<div class="card-info">
			<AText :modifiers="['bold', 'uppercase']">
				{{ t("scratchCards.promoCard.title") }}
			</AText>
			<AText variant="topeka" class="subtitle" :modifiers="['semibold']">
				{{ t("scratchCards.promoCard.subtitle") }}
			</AText>
		</div>
		<AText variant="topeka" :modifiers="['semibold']">
			<i18n-t keypath="scratchCards.promoCard.timer">
				<template #key>
					<AText variant="topeka" class="text-coro" gradient="var(--goeteborg)" :modifiers="['extrabold']">
						<span>{{ durationLeft }}</span>
					</AText>
				</template>
			</i18n-t>
		</AText>
		<MScratchCardsProgressBar
			v-if="!claimStatus"
			class="progress-promo-bar"
			:value="activeCard?.progress?.pointsComplete ?? 0"
			:maxValue="activeCard?.progress?.points ?? 0"
			:hideProgressBar="!!goldenCardData?.timer"
		/>
		<AButton variant="primary" :disabled="!!goldenCardData?.timer" size="s">
			<NuxtIcon v-if="claimStatus" name="22/scratch-ticket" class="feature-icon" filled />
			<AText :modifiers="['semibold']" class="text-tlalnepantla text-carabanchel">
				{{ !goldenCardData ? btnText : goldenCardTimeLeft }}
			</AText>
		</AButton>
	</ACard>
</template>

<style lang="scss" scoped>
.card-scratch {
	padding: 16px;
	justify-content: center;
	gap: 12px;
	background: var(--gliwice);

	.subtitle {
		max-width: 173px;
	}

	.card-info {
		display: flex;
		flex-direction: column;
		gap: 8px;
	}

	button {
		min-width: 140px;
		width: max-content;
		gap: 4px;

		&:disabled {
			opacity: 0.7;
			cursor: not-allowed;
		}
	}

	.progress-promo-bar {
		max-width: 196px;
		align-items: flex-start;
		min-height: 30px;
	}

	.free {
		position: absolute;
		right: 12px;
		top: 0;
		width: 60px;
		height: 49px;
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 0 gutter(0.4) gutter(2) 0;

		&:deep(svg) {
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
		}

		&-text {
			position: relative;
			z-index: 2;
		}
	}

	.feature-icon {
		font-size: 22px;
		display: inline-flex;

		&:deep(svg) {
			margin: 0;
		}
	}
}
</style>
